.form-container {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}
.right-img-container {
    background-color: "#F4F7FC";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}
.car-step1-img {
    height: 300px;
    width: 300px;
}
.step2-car-nextbtn button {
    background: #0093dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.h6-text-heading {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.p-diff_car {
    text-align: justify;
}
.img-cls {
    width: 75px;
    height: 75px;
}
.col-cls {
    margin-top: 2rem;
}

.link-terms {
    text-decoration: underline;
    color: blue;
}

.modal-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-block-close-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* margin-left: 9.375rem; */
}

.modal-btn-size {
    position: static;
    margin-left: 20rem;
    margin-top: -1rem;
}
.modal-btn-icon {
    font-size: 20px;
    color: black;
}
@media only  screen and (max-width:1024px)
and (min-width:768px){
	.modal-btn-size {
        position: static;
        margin-left: 28rem;
        margin-top: -1rem;
    }
    .modal-block-close-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-left: 1.875rem;
    }
}
@media only  screen and (max-width:667px)
and (min-width:375px){
	.modal-block-close-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-left: -10rem;
    }
}


.p-heading-faqs {
    font-family: 'ErasDemiITC';
    margin-bottom: 3rem;
    font-size: 1.25rem;
    font-weight: 600;
}

.accordion-item{
    border: 0px;
    margin-bottom: 20px;
}

.cattle-form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    margin-right: 2rem;
    width: 200px;
    display: flex;
    flex-direction: column;
  }
  
  .cattle-form-controll {
    height: 55px;
    width: 200px;
    border-radius: 3px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
 
  .cattle-form-controll:hover {
    border: 0.5px solid black;
  }
  
  .cattle-form-control-placeholderr {
    position: absolute;
    left: 30px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    pointer-events: none;
    /* z-index: 0; */
  }
  
  .cattle-form-controll:focus+.cattle-form-control-placeholderr, 
  .cattle-form-controll:valid+.cattle-form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }
  
  .cattle-form-controll:focus {
    border: 1px solid #0093DD;
  }

  .cattle-img1{
    width: 100%;
  }

  .no-pad{
    margin-left: -15px;
    margin-right: -15px;
  }

  .banner-text{
    font-family: 'ErasMediumITC';
    color: #29166F;
    font-weight: 400;
    font-size: 28px;
    position: absolute;
    z-index: 9;
    text-align: left;
    padding: 30px;
  }
  .border-top{
    background: #0093DD;
    position: absolute;
    width: 146px;
    height: 8px;
    margin: 20px 30px 30px 30px;
  }

  .textPrimary{
    font-weight: 600;
  }

  .form label{
    font-weight: 600;
  }

  .form button{
    z-index: 9;
  }


  .react-datepicker button{
    width: 15% !important;
  }

  .accordion-button{
    background: #e7f1ff;
  }


  /* ------input field-------- */
.cattle2-form-groupp {
  position: relative;
  margin-bottom: 1.5rem;
  margin-right: 2rem;
  width: 270px;
  display: flex;
  flex-direction: column;
}

.cattle2-form-controll {
  height: 55px;
  width: 200px;
  border-radius: 3px;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  padding-left: 15px;
  outline: none;
  font-family: Calibri,sans-serif;
  font-size: 18px;
}

.cattle2-form-controll:hover {
  border: 0.5px solid black;
}

.cattle2-form-control-placeholderr {
  position: absolute;
  left: 30px;
  /* bottom: 20px; */
  top: 15px;
  padding: 0px 5px 0px 5px;
  margin-left: 8px;
  transition: all 300ms;
  opacity: 0.5;
  pointer-events: none;
  /* z-index: 0; */
}

.cattle2-form-controll:focus+.cattle2-form-control-placeholderr, 
.cattle2-form-controll:valid+.cattle2-form-control-placeholderr {
  font-size: 95%;
  top: 10px;
  transform: translate3d(-10%, -90%, 0);
  opacity: 1;
  background-color: #fff;
  color: #0093dd;
}

.cattle2-form-controll:focus {
  border: 1px solid #0093DD;
}





.form-groupp {
  position: relative;
  margin-bottom: 1.5rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
}

.form-control {
  height: 50px;
  width:  339px;
  border-radius: 3px;
  border: 0.5px solid #0093DD;
  border-radius: 10px;
  padding-left: 15px;
  outline: none;
  font-family: Calibri,sans-serif;
  font-size: 18px;
}

/* .form-control:hover {
  border: 0.5px solid black;
} */

.form-control-placeholderr {
  position: absolute;
  left: 30px;
  /* bottom: 20px; */
  top: 15px;
  padding: 0px 5px 0px 5px;
  margin-left: 8px;
  transition: all 300ms;
  opacity: 0.5;
  pointer-events: none;
  /* z-index: 0; */
}

.form-control:focus+.form-control-placeholderr, 
.form-control:valid+.form-control-placeholderr {
  font-size: 95%;
  top: 10px;
  transform: translate3d(-10%, -90%, 0);
  opacity: 1;
  background-color: #fff;
  color: #0093dd;
}

/* .form-control:focus {
  border: 1px solid #0093DD;
} */


/* ------end input field-------- */


.step2-car-insurance {
  /* height: 420px; */
  background-color: #fff;
  margin: 70px 0 0 1.5%;
  width: 97%;
}

/* .step2-progress-img {
  background-color: seagreen;
  width: 30px;
  height: 312px;
} */

.step2-top-img {
  background-color: #F2F6FF;
  width: 128px;
  height: 128px;
  padding: 40px 10px;
  border-radius: 100%;
  /* margin-top: -60px; */
  margin: -50px 45%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .step2-content {
  background-color: skyblue;
  height: 312px;
  margin-left: 270px;
  text-align: center;
} */

/* .step2-content h2 {
  padding-top: 50px;
} */

.step2-content p {
  text-align: center;
  padding-top: 20px;
}

.step2-car-nextbtn {
  /* style={{ textAlign: 'center',paddingBottom:'50px' }} */
  text-align: center
}

.step2-car-nextbtn button {
  text-align: center;
  font-family: 'ErasDemiITC';
  width: 200px;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #0093DD;
  color: #fff;
  border: 0.3px solid #949494;
  font-size: 17px;
  padding: 10px;
  outline: none;
}

/* .step2-car-nextbtn {
  width: 210px;
  height: 62.4px;
  border-radius: 14px;
  transition: 0.3s;
  color: #949494;
  background: #fff;
  border: 0.3px solid #949494;
  
  font-size: 20.5px;
  padding: 10px;
  outline: none;
} */

.step2-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step2-img img {
  width: 27rem;
  height: 21rem;
}

.step2-car-input {
  padding-right: 0px;
}
.cattle2-a{
  font-size: 20px;
  text-align: center;
  color: #362578;
  margin-top: 10px;
  font-family: 'ErasMediumITC';
  text-decoration: underline;
}
.cattle2-a a{
  cursor: pointer;
}

.accordion-header-2 button{
background-color: #F4F6F9 !important;
}

.css-16xfy0z-control{
border-radius: 10px !important;
}

@media (max-width: 576px){
  .step2-img{
      text-align: center;
  }
  .step2-img img {
      width: 22rem;
      height: 16rem;
      margin-bottom: 25px;
  }
}

@media (max-width: 768px){
  .step2-img{
      text-align: center;
  }
  .step2-img img {
      width: 22rem;
      height: 16rem;
      margin-bottom: 25px;
  }
}

/* radio */

/* only demo styles */

/* end only demo styles */

.radio-custom {
    opacity: 0;
    position: absolute;   
}

.radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.radio-custom-label {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px 1px;
    margin-right: 10px;
    text-align: center;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: #fff;
    line-height: 14px;
    background: #00a0e3;
    border: 2px solid #00a0e3;
}

.radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; /* focus style */
}

.side-label{
  font-family: "ErasDemiITC";
  padding-top: 5px !important;
  padding-left: 20px;
  color: #3a3285;
}

.pt-5{
  padding-top: 5px !important;
}
.mt-20{
  margin-bottom: 20px !important;
}
.pl-20{
  padding-left: 20px;
}

.sub-label{
  font-family: "ErasDemiITC";
  padding-top: 5px !important;
  padding-bottom: 10px;
  color: #3a3285;
}

.custom-file-upload {
  border: 1px solid #00a0e3;
  background-color: #00a0e3;
  color: #fff;
  font-family: "ErasDemiITC";
  display: inline-block;
  padding: 6px 20px;
  cursor: pointer;
  margin-bottom: 15px;
}

.img-range{
  border: 1px dashed #949494;
  padding: 5px;
  position: relative;
  width: 112px;
}

.img-range .close-icon{
  position: absolute;
  top: -15px;
  right: -15px;
  border: 1px solid #796c6c;
  border-radius: 50px;
  padding: 1px 4px;
  font-size: 12px;
  background: #fff;
  color: #796c6c;
  cursor: pointer;
}

/* new */

.cattle-btn-primary button{
color: #fff;
background-color: #0d6efd !important;
border-color: #0d6efd !important;
}

.form .col input,
.form .col select,
.form .col .searchable-select .css-319lph-ValueContainer {
height: 55px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col .searchable-select .css-1insrsq-control {
background-color: white;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col input,
.form .col textarea,
.form .col select {
border-radius: 3px;
border: 0.5px solid #d3d3d3;
border-radius: 10px;
padding-left: 15px;
padding-right: 15px;
padding-top: 10px;
padding-bottom: 10px;
background: #fff;
outline: none;
font-family: Calibri, sans-serif;
font-size: 18px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col input[type="radio"] {
-ms-transform: scale(1.5); /* IE 9 */
-webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
transform: scale(1.5);
cursor: pointer;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col span {
font-size: 16px;
color: #656565;
font-family: "calibri";
font-weight: bold;
background: #fff;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col .searchable-select {
height: 55px;
border-radius: 3px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col .searchable-select .css-1s2u09g-control {
height: inherit;
border-radius: 10px;
padding: 0;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col .searchable-select .css-1insrsq-control {
border-radius: 10px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col .searchable-select .css-1s2u09g-control .css-6j8wv5-Input {
grid-area: 1/1/1/1;
margin: 0;
padding: 0;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col label {
color: #00a0e3 !important;
font-size: 15px !important;
top: -1.5rem;
margin-left: 10px;
max-width: 90%;
left: 0;
background-color: white;
padding: 0.4rem;
font-family: "calibri";
  font-weight: bold;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form button {
width: 150px;
border-radius: 15px;
font-size: 12px;
font-weight: normal;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.p-error {
font-size: 0.7rem;
color: red;
font-family: "Calibri";
margin: 10px 5px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.file-btn {
width: 140px !important;
height: 40px !important;
font-size: 16px !important;
font-weight: 400 !important;
align-items: center !important;
margin-left: 0.8rem;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.file-choose label {
font-size: 12px;
font-weight: 100;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.img-preview {
width: 200px;
min-height: 190px;
padding: 10px;
position: relative;
background: #eee;
border: 2px dashed #949494;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
margin-left: 13px;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.img-preview img {
height: -webkit-fill-available;
max-width: 100%;
object-fit: contain;
margin: auto;
display: block;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.img-preview .clear {
position: absolute;
right: -32px;
top: -30px;
height: 60px;
width: 60px;
cursor: pointer;
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.__loading {
display: flex;
flex-direction: column;
row-gap: 0.5rem;
justify-content: center;
align-items: center;
height: calc(100vh - 200px);
width: calc(100vw - 15rem);
}

.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.__loading h1 {
font-size: 1.3rem;
}

.number{
font-weight: 400;
font-size: 15px;
color: #525253;
padding-bottom: 10px;
}

.ml-10{
margin-left: 10px;
}

.label-text{
font-weight: 400;
font-size: 15px !important;
color: #525253 !important;
}
.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.radio-label-text{
font-size: 18px !important;
color: #29166F !important;
font-weight: 400 !important;
font-weight: bold !important;
}
.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.label-amount{
font-size: 14px;
font-family: Calibri;
}


@media (max-width: 1295px) {
.flex-column {
  flex-direction: column!important;
}
.d-flex {
  display: flex!important;
}
.form .col label {
  font-size: 12px !important;
  padding: 0;
}
}
