.Policies {
    background-color: #edf5fb;
}

.bg-secondary {
    background-color: #7d90b2!important;
}

.bg-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
    background-color: #1565d8 !important;
}

.bg-success {
    background-color: #29cc97 !important;
    border-color: #29cc97 !important;
}

.bg-danger {
    background-color: #f12b2c !important;
    border-color: #f12b2c !important;
}

.bg-warning {
    background-color: #fec400 !important;
    border-color: #fec400;
}


.btn-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
    background-color: #1565d8 !important;
}

.icueTopBarContainer {
    box-shadow: 1rem 0.5rem 1rem rgba(0,0,0,0.15);
}

.icueLogo {
    width: 14.5rem;
    /* color: transparent */
}

/* Search Box */

.icueSearchBoxControl {
    background-color: #e9ecef;
    border:1px solid #ced4da !important;
    border-left: 0 !important;
    font-family: 'Calibri';
}

.icueProfilePic {
    width: 2rem;
}

.bi-bell {
    font-size: 1.2rem;
}

.icueNofIndic {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    top:0;
    left: 50%;
}

.icueNotifyBox {
    visibility: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    width: 15rem;
    /* border: 2px solid red; */
    height: 300px;
    color: #fff;
    background-color: #0093dd;
}

/* Responsive Side Nav */
.icueRespSideBar {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: cyan; */
}

.icueSideBar {
    position: absolute;
    display: none;
    width: 100%;
    background-color: white;
    transition: all 0.5s ease;
    z-index: 1;
}
.icueMail{
    font-family: 'Calibri';
    font-style: normal;
font-weight: 600;
font-size: 15px;
color: #3A3285;
}

.icueUserName{
font-family: 'Calibri';
font-style: normal;
 font-weight: 300;
/* font-size: 21px; */
/* line-height: 20px; */
color: #252733
}
/* Side Nav */
/* #backgroundOverlay{
    background-color:transparent;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
} */

/* #icueRespHumburger {
    position: absolute;
} */

#icueCloseIcon {
    display: none;
}

.icueNavBarList {
    padding: 0;
    /* margin: 0; */
    /* display: block; */
    width: 100%;
    list-style-type: disc;
    list-style-type: none;
    font-family: 'Calibri';
}

.icueNavBarList > li.active {
    text-decoration: none;
    padding-left: 1rem;
    color: #334d6e;
    background: #f4f9ff;
    border-left: 3px solid #334d6e;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-family: 'Calibri';
}

.icueNavBarItem {
    position: relative;
    padding: 0.9rem 0;
    padding-left: 1.5rem;
    font-weight: 700;
    border-bottom: 0.5px solid lightgrey;
    font-family: 'Calibri';
}

.icueNavBarItem::before {
    position: absolute;
    content: '';
    top: 45%;
    left:3%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #334d6e;
    /* text-transform: capitalize; */
}

.icueNavDot {
    position: relative;
}
.icueNavDot::before,
.icueNavDotClaim::before {
    position: absolute;
    content: '';
    top: 24%;
    left: 3%;
    width: 5px;
    height: 5px;
}

.icueNavDotClaim {
    position: relative;
}

.icueNavDotClaim::before {
    top: 17%;
}

.icueNavDotSet {
    position: relative;
}

.icueNavDotSet::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    color: transparent;
    background-color: transparent;
}

.icueNavBarLink {
    display: block;
    color: #334d6e;
    text-decoration: none;
    font-family: 'ErasDemiITC';
    /* border: 2px solid red; */
}

.icueSubNavLink {
    color: #334d6e;
    font-size: 0.8rem;
    font-weight: normal;
    text-decoration: none;
}


/* Add Memeber */

.btnIcon,
.btnIcon2 {
    position: relative;
}

.btnIcon::before,
.btnIcon2::before {
    position: absolute;
    content: '\2192';
    bottom: -30%;
    right: 5%;
    font-size: 3rem;
}

.btnIcon::after,
.btnIcon2::after {
    position: absolute;
    content: '';
    bottom: 49%;
    right: 16%;
    border-width: 3px 12px;
    border-style: solid;
    /* border-color: #007bff; */
    border-color: #1565d8;
}

.btnIcon2::after {
    right: 10.3%;
}

#addModalHeader,
#editModalHeader,
#deleteModalHeader {
    /* background-color: green!important; */
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
}

#addModalBody,
#editModalBody,
#deleteModalBody {
    padding-top: 0;
}

.addModalBody .form-group .form-control {
    border: 1px solid #b9c0cc !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '\00BB';
    color: black;
    font-size: 1rem;
    font-family: 'Calibri';
}

/* LMS Styles */
.icueBgGradient1 {
    background: linear-gradient(to left,#109cf1, rgb(21,101,216));
}

.icon1 {
    color: #109cf1;
}

.icueBgGradient2 {
    background: linear-gradient(to left,rgb(41,204,151), rgb(36,175,36));
}

.icon2 {
    color:rgb(41,204,151);
}

.icueBgGradient3 {
    background: linear-gradient(to left,rgb(232, 100, 100), rgb(201,12,12));
}

.icon3 {
    color: rgb(232, 100, 100);
}

.icueBgGradient4 {
    background: linear-gradient(to left,rgb(120,113,183), rgb(58,50,133));
    background: linear-gradient(to left,#885af8, #3a3285);
}

.icon4 {
    color: rgb(120,113,183);
    color: #885af8;
}

.icueBgDanger {
    /* background-color: rgb(250,84,87); */
    background-color: #fa5457;
}

.icueTextLight {
    color: rgb(125, 144, 178);
}

.icueBgPrimary {
    background-color: rgb(58,50,133);
    background-color: #3a3285;
}

.icueTextPrimary {
    color: rgb(58,50,133);
    color: #3a3285;
}

.icueTextInfo {
    /* color: rgb(21,101,216); */
    color: #0a65ff;
    /* color: #00a0e4; */
}

.icueTextInfoLight {
    color: #109cf1;
}

.icueTextSuccess {
    color:rgb(36,175,36);
}

.icueTextLightDanger {
    color: rgb(250,84,87)
}

.icueTextDanger {
    color:rgb(253,29,30);
}

.icueTextLigthInfo {
    color: rgb(178, 226, 247);
}

.icueCard {
    border: 2px solid transparent;
}

.icueCard:hover {
    /* cursor: pointer; */
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    border: 2px solid #109cf1; 
}

.icueCard:hover h3:first-child {
    color: #109cf1;
    text-decoration: underline;
}

.icueShadowLight {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

}
.icueShadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.icueBgLightGrey {
    background-color: rgb(235, 239, 242);
}

.icueRounded {
    border-radius: .9rem!important;
}

/* Super Admin */

.icueBorBottomPrimary {
    border-bottom: 3px solid #3a3285;
}

.icueBorBottomLightInfo {
    border-bottom: 3px solid #109cf1;
}

.icueBorBottomLightDanger {
    border-bottom: 3px solid rgb(250,84,87);
}

#icueLeadInfoForm .form-control,
#icueLeadInfoForm .form-select {
    font-size: small;
}

/* Reset Password */
#icueResetPassEmail::-webkit-input-placeholder {
    text-align: center;
}

/* Firefox 18- */
#icueResetPassEmail:-moz-placeholder {
    text-align: center;  
}
 
/* Firefox 19+ */
#icueResetPassEmail::-moz-placeholder {
    text-align: center;  
}
 
#icueResetPassEmail:-ms-input-placeholder {  
    text-align: center; 
}

       /* Radio Button */
       .btn-toggle {
        margin: 0 4rem;
        padding: 0;
        position: relative;
        border: none;
        height: 1.5rem;
        width: 3rem;
        border-radius: 1.5rem;
        color: #6b7381;
        background: #bdc1c8;
    }
    .btn-toggle:focus,
    .btn-toggle.focus,
    .btn-toggle:focus.active,
    .btn-toggle.focus.active {
        outline: none;
    }
    .btn-toggle:before,
    .btn-toggle:after {
        line-height: 1.5rem;
        width: 4rem;
        text-align: center;
        font-weight: 600;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        transition: opacity 0.25s;
    }
    .btn-toggle:before {
        content: "Off";
        left: -4rem;
    }
    .btn-toggle:after {
        content: "On";
        right: -4rem;
        opacity: 0.5;
    }
    .btn-toggle > .handle {
        position: absolute;
        top: 0.1875rem;
        left: 0.1875rem;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 1.125rem;
        background: #fff;
        transition: left 0.25s;
    }
    .btn-toggle.active {
        transition: background-color 0.25s;
    }
    .btn-toggle.active > .handle {
        left: 1.6875rem;
        transition: left 0.25s;
    }
    .btn-toggle.active:before {
        opacity: 0.5;
    }
    .btn-toggle.active:after {
        opacity: 1;
    }
    .btn-toggle.btn-sm:before,
    .btn-toggle.btn-sm:after {
        line-height: -0.5rem;
        color: #fff;
        letter-spacing: 0.75px;
        left: 0.4125rem;
        width: 2.325rem;
    }
    .btn-toggle.btn-sm:before {
        text-align: right;
    }
    .btn-toggle.btn-sm:after {
        text-align: left;
        opacity: 0;
    }
    .btn-toggle.btn-sm.active:before {
        opacity: 0;
    }
    .btn-toggle.btn-sm.active:after {
        opacity: 1;
    }
    .btn-toggle.btn-xs:before,
    .btn-toggle.btn-xs:after {
        display: none;
    }
    .btn-toggle:before,
    .btn-toggle:after {
        color: #6b7381;
    }
    .btn-toggle.active {
        background-color: #007bff;
    }
    
    .btn-toggle.btn-lg.btn-sm:before,
    .btn-toggle.btn-lg.btn-sm:after {
        line-height: 0.5rem;
        color: #fff;
        letter-spacing: 0.75px;
        left: 0.6875rem;
        width: 3.875rem;
    }
    .btn-toggle.btn-lg.btn-sm:before {
        text-align: right;
    }
    .btn-toggle.btn-lg.btn-sm:after {
        text-align: left;
        opacity: 0;
    }
    .btn-toggle.btn-lg.btn-sm.active:before {
        opacity: 0;
    }
    .btn-toggle.btn-lg.btn-sm.active:after {
        opacity: 1;
    }
    .btn-toggle.btn-lg.btn-xs:before,
    .btn-toggle.btn-lg.btn-xs:after {
        display: none;
    }
    .btn-toggle.btn-sm {
        margin: 0 0.5rem;
        padding: 0;
        position: relative;
        border: none;
        height: 1.5rem;
        width: 3rem;
        border-radius: 1.5rem;
    }
    .btn-toggle.btn-sm:focus,
    .btn-toggle.btn-sm.focus,
    .btn-toggle.btn-sm:focus.active,
    .btn-toggle.btn-sm.focus.active {
        outline: none;
    }
    .btn-toggle.btn-sm:before,
    .btn-toggle.btn-sm:after {
        line-height: 1.5rem;
        width: 0.5rem;
        text-align: center;
        font-weight: 600;
        font-size: 0.55rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        transition: opacity 0.25s;
    }
    .btn-toggle.btn-sm:before {
        content: "Off";
        left: -0.5rem;
    }
    .btn-toggle.btn-sm:after {
        content: "On";
        right: -0.5rem;
        opacity: 0.5;
    }
    .btn-toggle.btn-sm > .handle {
        position: absolute;
        top: 0.1875rem;
        left: 0.1875rem;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 1.125rem;
        background: #fff;
        transition: left 0.25s;
    }
    .btn-toggle.btn-sm.active {
        transition: background-color 0.25s;
    }
    .btn-toggle.btn-sm.active > .handle {
        left: 1.6875rem;
        transition: left 0.25s;
    }
    .btn-toggle.btn-sm.active:before {
        opacity: 0.5;
    }
    .btn-toggle.btn-sm.active:after {
        opacity: 1;
    }
    .btn-toggle.btn-sm.btn-sm:before,
    .btn-toggle.btn-sm.btn-sm:after {
        line-height: -0.5rem;
        color: #fff;
        letter-spacing: 0.75px;
        left: 0.4125rem;
        width: 2.325rem;
    }
    .btn-toggle.btn-sm.btn-sm:before {
        text-align: right;
    }
    .btn-toggle.btn-sm.btn-sm:after {
        text-align: left;
        opacity: 0;
    }
    .btn-toggle.btn-sm.btn-sm.active:before {
        opacity: 0;
    }
    .btn-toggle.btn-sm.btn-sm.active:after {
        opacity: 1;
    }
    .btn-toggle.btn-sm.btn-xs:before,
    .btn-toggle.btn-sm.btn-xs:after {
        display: none;
    }
/* /Radio Buttons */

.icueAssChgBox {
    display: none;
}

.custom-file-upload{
    background: #f7f7f7; 
    padding: 8px;
    border: 1px solid #e3e3e3; 
    border-radius: 5px; 
    border: 1px solid #ccc; 
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 0.8rem;
    margin-bottom: 2rem;
}
.Line{
    margin-top: 2.5rem;
}

#icueAddRfqForm {
    border-bottom: 2px solid #109cf1;
}

#choose-file-label {
    width: 40%;
}

#icueRfqUploadedDoc {
    /* margin-left: 0.8rem; */
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #109cf1;
    width: 100%;
}

.modal-header {
    border-bottom: 2px solid #b9c1cf;
}

.icueGenInfoLabel,
.icuePersonalInfoLabel,
.icueVehicleInfoLabel,
.icuePaymentInfoLabel,
.icueRFQInfoLabel,
.icueFollowInfoLabel {
    font-weight: 500;
    text-transform: capitalize;
}

#icueSaveGenInfoDetails,
#icueSavePersonalInfoDetails,
#icueSaveVehicleInfoDetails,
#icueSavePaymentInfoDetails,
#icueSaveRFQInfoDetails,
#icueSaveFollowInfoDetails {
    display: none;
}

.fw500 {
    font-weight: 500;
}

/* Super Admin RFQ Table */
#icueRfqTable tr th,
#icueRFQInfoForm tr th,
#icueFollowUpTable tr th {
    font-size: 0.8rem;
}

#icueRfqTable tr td,
#icueRFQInfoForm tr td
#icueFollowUpTable tr td {
    font-size: 0.9rem;
    font-weight: 500;
}

#icueRfqTable .table>:not(caption)>*>*,
#icueRFQInfoForm .table>:not(caption)>*>*,
#icueFollowUpTable .table>:not(caption)>*>* {
    border-bottom-width: 0 !important;
    /* box-shadow: inset 0 0 0 9999px red !important; */

}

#icueRfqTable .table-striped>tbody>tr:nth-of-type(odd),
#icueRFQInfoForm .table-striped>tbody>tr:nth-of-type(odd),
#icueFollowUpTable .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #edf5fb !important;
    /* background-color: rgb(244,247,252) !important; */
}

/* /Super Admin RFQ Table */

@media screen and (min-width: 567px) and (max-width: 991px) {
    .icueNavBarItem::before {
        position: absolute;
        content: '';
        top: 45%;
        left:0.7%;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #334d6e;
        /* text-transform: capitalize; */
    }
    
    .icueNavDot {
        position: relative;
    }
    .icueNavDot::before {
        position: absolute;
        content: '';
        top: 24%;
        left: 0.7%;
        /* border-style: solid;
        border-width: 2.5px 2.5px;
        border-color: #334d6e; */
        width: 5px;
        height: 5px;
    
        /* font-size: 30px; */
    }
    
    .icueNavDotSet::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        color: transparent;
        background-color: transparent;
    }
}

.fs-7 {
    font-size: 0.8rem;
    font-weight: 500;
}
.paginate_button {
    font-size: 0.7rem !important;
    display: none !important;
    background-color: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    display: inline-block !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
    display: inline-block !important;
}

.page-link {
    /* padding: 0 !important; */
    /* padding-top: 5px; */
    padding: .195rem 0.25rem;
    border: 0;
}

/* Search Box */
.dataTables_filter {
   float: left !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    /* background-color: #007bff; */
    background-color: #fff !important;
    /* border-color: #007bff; */
    border-color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0;
}

/* Info Style */
.dataTables_wrapper .dataTables_info {
    /* clear: both !important; */
    float: right;
    font-size: 0.7rem;
    /* display: flex; */
    /* flex-direction: row-reverse; */
    /* text-align: right; */
}

/* Table odd rows color */
/* .table-striped>tbody>tr:nth-of-type(odd) {
   background-color: #fff !important;
} */

table tr {
    border: 0px solid lightgrey !important;
    border-left: 0;
    border-right: 0;
}

/* Table Head Titles */
.table.dataTable thead .sorting {
    text-transform: uppercase;
    font-size: 0.7rem;
    border-bottom: 1px solid lightgrey;
}


#lmsDataTable th {
    font-size: 0.7rem;
    font-family:'ErasBoldiITC';
   
}

#lmsDataTable td {
    font-size: 0.9rem;
    text-transform: capitalize;
    font-family: 'Calibri';
    
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: white;
    color: #109cf1;
    border-color: #dee2e6 #dee2e6 #fff;
    cursor: pointer;
}
.nav-tabs .nav-link {
    font-weight: 600;
    color: grey;
    background-color: transparent;
    cursor: pointer;
}
table.dataTable thead .sorting{
    background-image: url() !important;
}
/* #lmsDataTable,
#lmsDataTable th,
#lmsDataTable td
{
  
} */
.table thead th{
    border-bottom: 1px #dee2e6;
    border-top: 0px;
    border-left: 0px;
    font-family: 'ErasDemiITC';
}
.icueNavBarList > li.danger {
    text-decoration: none;
    padding-left: 1rem;
    color: #FA5457;
    background:#ffecec;
    border-left: 3px solid #FA5457;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-family: 'Calibri';

}

a:hover,a:focus{
    outline: none;
    text-decoration: none;
}
.tab .nav-tabs{ border-bottom: 2px solid #e8e8e8; }
.tab .nav-tabs li a{
    display: block;
    padding: 10px 20px;
    margin: 0 5px 1px 0;
    background: #fff;
    font-size: 18px;
    font-weight: 700;
    color: #112529;
    text-align: center;
    border: none;
    border-radius: 0;
    z-index: 2;
    position: relative;
    transition:all 0.3s ease 0s;
    cursor: pointer;

}
.tab .nav-tabs li a:hover,
.tab .nav-tabs li.active a{
    color: #198df8;
    border: none;
    cursor: pointer;
}
.tab .nav-tabs li.active a:before{
    /* content: "\f107"; */
    font-family: "Calibri";
    font-weight: 900;
    font-size: 18px;
    font-weight: 700;
    color: #198df8;
    margin: 0 auto;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    cursor: pointer;
}
.tab .nav-tabs li.active a:after{
    content: "";
    width: 100%;
    height: 3px;
    background: #198df8;
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}
.tab .tab-content{
    padding: 30px 20px 20px;
    margin-top: 0;
    background: #fff;
    font-size: 15px;
    color: #7a9181;
    line-height: 30px;
    border-radius: 0 0 5px 5px;
}
.tab .tab-content h3{
    font-size: 24px;
    margin-top: 0;
}
@media only screen and (max-width: 479px){
    .tab .nav-tabs li{
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    .tab .nav-tabs li.active a:before{
        content: "\f105";
        bottom: 15%;
        left: 0;
        right: auto;
        cursor: pointer;
    }
}



section {
    padding: 10px 0;
}

section .section-title {
    text-align: center;
    color: #198df8;
    margin-bottom: 20px;
    text-transform: uppercase;
}
#tabs{
	background: white;
    color: #eee;
}
#tabs h6.section-title{
    color: #198df8;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #198df8;
    background-color: transparent;
    border-color: transparent transparent #198df8;
    border-bottom: 4px solid !important;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #198df8;
    font-size: 20px;
    cursor: pointer;
}
.nav {
    display: 20px;
    /* flex-wrap: wrap; */
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}



.container1{
    margin-top: 10px;
    background-color: white;
    }
    .tab-head{
    padding-left: 0px !important;
    padding-right: 0px !important;
    }
    .nav-item a:focus{
    outline: unset;
    }
    /* .nav-item a:hover{
    border: 1px solid #fff !important;
    } */
    .nav-item a{
    color: black !important;
    font-weight: 600;
    padding-left: 28px;
    padding-right: 28px;
    }
    .nav-item .active{
    color: #198df8 !important;
    border:none !important;
    border-bottom: 3px solid #198df8 !important;
    font-weight: 400;
    }
    .nav-item .active:hover{
    border:none !important;
    border-bottom: 3px solid #198df8 !important;
    }
    .nav-tabs{
    border-bottom: none !important;
    }
    .tab-pane h5{
    border-left: 2px solid #198df8;
    }
    .tab-pane p{
    border-top: 1px solid #c1c1c1;
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem ;
        border-radius: 12px ;
    }
    .results tr[visible='false'],
    .no-result{
      display:none;
    }
    
    .results tr[visible='true']{
      display:table-row;
    }
    
    .counter{
      padding:8px; 
      color:#ccc;
    }
    .highlight {
        background-color: #fff34d;
        -moz-border-radius: 5px; /* FF1+ */
        -webkit-border-radius: 5px; /* Saf3-4 */
        border-radius: 5px; /* Opera 10.5, IE 9, Saf5, Chrome */
        -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* FF3.5+ */
        -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Saf3.0+, Chrome */
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Opera 10.5+, IE 9.0 */
    }
    
    .highlight {
        padding:1px 4px;
        margin:0 -4px;
    }