.login-card {
        margin: 100px 5% ;
        width: 90%;
        background: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 37px;
        text-align: center;
        padding-bottom: 30px;
        position: relative;
        
        
        }
        .cross-icon{
        position: absolute;
        top:30px;
        right: 20px;
        cursor: pointer;
        
        }
        .login-card img{
        width: 340px;
        height: 60px;
        margin: 30px 0;
        }
        .login-chechbox {
        margin: 20px 0;
        }
        .new-user{
        margin: 10px 0;
        }
        .login-card h3{
        margin: 30px 0;
        }
        .login-form{
        width:50%;
        }
        
        .loginimg{
        width:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        }
        .loginimg img{
        width: 287px;
        height: 279px;
        }
        
        .inputfield{
        width: 350px;
        height: 60px;
        line-height: 50px;
        background: #fafafa;
        font-size: 16px;
        box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
        border-radius: 5px;
        padding: 0 20px;
        font-size: 16px;
        color: #666;
        
        
        outline: none;
        margin: 20px 0;
        
        border: none;
        }
        
        .login-form a{
        text-decoration: none;
        }
        .login-btn{
                width: 270px;
                height: 50px;
                border: none;
                border-radius: 25px;
                background-color: #0093dd;
                color: #fff;
                font-weight: normal;
                margin: 20px 0;
                font-family: 'ERASDEMIITC';
                font-size: 1rem;
                margin-top: 10px;
        }
        .social-login{
        width: 380px;
        border:none;
        border-radius: 25px;
        height: 40px;
        color: #fff;
        margin: 10px 0;
        }
        .facebook{
        background-color: #184AD8;
        }
        .google{
        background-color: #D81818;
        }
        
        
        @media (max-width:855px){
        .loginimg{
        display: none;
        }
        .login-form{
        width: 100%;
        }
        }
        .hr{
                width: "60px";
                height: "4px";
                background-color: "#29166f ";
                border: "none";
                margin-left: "45%";
              
        }

        .heading {
                font-family: 'ERASDEMIITC';
        }

        .align-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
        }
        .remember-text {
                color: #535252;
                font-family: 'ERASMEDIUMITC';
                font-size: 1rem;
                margin-left: 10px;
        }
        .redirect-text {
                cursor: pointer;
                color: rgb(41, 22, 111);
                font-family: 'ERASMEDIUMITC';
        }
        .or-text{
                color: #535252;
                font-family: 'ERASMEDIUMITC';
                font-size: 1rem;  
        }
        .column {
                display: flex;
                 flex-direction: column; 
                 align-items: center;
        }