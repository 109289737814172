/* ------input field-------- */
.car2-form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    width: 270px;
    display: flex;
    flex-direction: column;
  }
  
  .car2-form-controll {
    height: 55px;
    width: 270px;
    border-radius: 3px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
 
  .car2-form-controll:hover {
    border: 0.5px solid black;
  }
  
  .car2-form-control-placeholderr {
    position: absolute;
    left: 30px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    pointer-events: none;
    /* z-index: 0; */
  }
  
  .car2-form-controll:focus+.car2-form-control-placeholderr, 
  .car2-form-controll:valid+.car2-form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }
  
  .car2-form-controll:focus {
    border: 1px solid #0093DD;
  }





  .form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
  }

  .form-control {
    height: 50px;
    width:  339px;
    border-radius: 3px;
    border: 0.5px solid #0093DD;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
 
  /* .form-control:hover {
    border: 0.5px solid black;
  } */
  
  .form-control-placeholderr {
    position: absolute;
    left: 30px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    pointer-events: none;
    /* z-index: 0; */
  }
  
  .form-control:focus+.form-control-placeholderr, 
  .form-control:valid+.form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }
  
  /* .form-control:focus {
    border: 1px solid #0093DD;
  } */


/* ------end input field-------- */


.step2-car-insurance {
    /* height: 420px; */
    background-color: #fff;
    margin: 70px 0 0 1.5%;
    width: 97%;
}

/* .step2-progress-img {
    background-color: seagreen;
    width: 30px;
    height: 312px;
} */

.step2-top-img {
    background-color: #F2F6FF;
    width: 128px;
    height: 128px;
    padding: 40px 10px;
    border-radius: 100%;
    /* margin-top: -60px; */
    margin: -50px 45%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .step2-content {
    background-color: skyblue;
    height: 312px;
    margin-left: 270px;
    text-align: center;
} */

/* .step2-content h2 {
    padding-top: 50px;
} */

.step2-content p {
    text-align: center;
    padding-top: 20px;
}

.step2-car-nextbtn {
    /* style={{ textAlign: 'center',paddingBottom:'50px' }} */
    text-align: center
}

.step2-car-nextbtn button {
    text-align: center;
    font-family: 'ErasDemiITC';
    width: 200px;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
}

/* .step2-car-nextbtn {
    width: 210px;
    height: 62.4px;
    border-radius: 14px;
    transition: 0.3s;
    color: #949494;
    background: #fff;
    border: 0.3px solid #949494;
    
    font-size: 20.5px;
    padding: 10px;
    outline: none;
} */

.step2-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.step2-img img {
    width: 27rem;
    height: 21rem;
}

.step2-car-input {
    padding-right: 0px;
}
.car2-a{
    font-size: 20px;
    text-align: center;
    color: #362578;
    margin-top: 10px;
    font-family: 'ErasMediumITC';
    text-decoration: underline;
}
.car2-a a{
    cursor: pointer;
}

@media (max-width: 576px){
    .step2-img{
        text-align: center;
    }
    .step2-img img {
        width: 22rem;
        height: 16rem;
        margin-bottom: 25px;
    }
}

@media (max-width: 768px){
    .step2-img{
        text-align: center;
    }
    .step2-img img {
        width: 22rem;
        height: 16rem;
        margin-bottom: 25px;
    }
}