.li-item {
    margin: 0.5rem;
}
.a-text {
    font-family: "Calibri" !important;
}

.img-space {
    margin-right: 10px;
}
.p-small-footer {
    margin: 0.5rem;
    font-size: 0.75rem !important;
}
.logo-image {
    margin: 0.5rem;
}
.h6-text {
    margin: 0.5rem;
}
.h6-size {
    font-size: 12px !important;
}
.rights-content {
    font-size: 0.75rem !important;
    color: #525253;
    font-family: "Calibri";
}
.licence-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}
.licence-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
   
}
.h6-text-a {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
}
.h6-text {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.logo{
    /* width: 217px; */
    height: 35px;
}
.footer-note-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer-note {
    text-align: center;
    font-size: 0.75rem !important;
    font-family: "Calibri" !important;
    color: #949494;
}
@media (max-width: 450px) {
    .container {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
